<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="500px" v-model="addCatalogDialog">
      <v-card>
        <v-toolbar class="primary elevation-0" dense dark>
          <v-toolbar-title class="ml-2 white--text">{{
            this.StoreObj.action == "CREATE" ? "Add Catalog" : "Edit Catalog"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="addCatalogDialogEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-6 pb-0">
          <v-form ref="form">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-select
                  label="Select Master Category"
                  v-model="Master_Category"
                  :readonly="StoreObj.action == 'EDIT'"
                  :items="GetAllMasterCategoriesList"
                  dense
                  class="FontSize field_label_size field_height"
                  outlined
                  item-text="category_name"
                  item-value="category_id"
                  :rules="[(v) => !!v || 'Required']"
                >
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-autocomplete
                  persistent-hint
                  :items="GetAllCategoriesListBasedOnMC"
                  outlined
                  dense
                  :readonly="StoreObj.action == 'EDIT'"
                  label="Category *"
                  :rules="[(v) => !!v || 'Required']"
                  class="FontSize field_label_size field_height pl-2"
                  item-text="category_name"
                  item-value="category_id"
                  v-model="category_id"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                <v-autocomplete
                  item-text="organization_name"
                  item-value="organization_id"
                  :rules="[(v) => !!v || 'Required']"
                  :items="
                    GetAllManufacturerList.filter(
                      (item) => item.organization_name != 'ALL'
                    )
                  "
                  outlined
                  dense
                  label="Manufacturer *"
                  class="FontSize field_label_size field_height"
                  v-model="manufacturer"
                  :readonly="StoreObj.action == 'EDIT'"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6">
                <v-autocomplete
                  item-text="service_type_name"
                  item-value="service_type_id"
                  :items="GetAllServiceTypeList"
                  outlined
                  dense
                  multiple
                  label="Service Type *"
                  class="FontSize field_label_size field_height ml-2"
                  v-model="serviceType"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-text-field
              outlined
              dense
              label="Service Name *"
              :rules="[(v) => !!v || 'Required']"
              class="FontSize field_label_size field_height"
              v-model="service_name"
            >
            </v-text-field>
            <v-text-field
              outlined
              dense
              label="Ticket Prefix *"
              class="FontSize field_label_size field_height"
              hint="Ticket Prefix must be 3 Characters"
              persistent-hint
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  /^(?=[a-zA-Z0-9@._%+-]{0,100}$)[a-z A-Z]{0,100}$/.test(v) ||
                  'Invalid',
                (v) => (v.length > 0 && v.length <= 3) || 'Invalid Value',
              ]"
              v-model="service_ticket_prefix"
              @keyup="callmethodUppercase(service_ticket_prefix)"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              label="Service Credits *"
              class="FontSize field_label_size field_height"
              v-model="service_credits"
              :rules="[
                (v) => !!v || 'Required',
                (v) =>
                  /^(?=[0-9@._%+-]{0,10}$)[0-9 +-]{0,15}$/.test(v) ||
                  'Invalid Credits',
              ]"
            ></v-text-field>
            <v-file-input
              multiple
              v-if="StoreObj.action == 'CREATE'"
              append-icon="mdi-paperclip"
              v-model="files"
              label="File input"
              outlined
              dense
              prepend-icon=""
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            small
            :loading="isLoading"
            @click="validateMethod()"
            class="mr-2 secondary white--text"
            ><span v-text="StoreObj.action == 'CREATE' ? 'ADD' : 'EDIT'"></span
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { GetAllCategories } from "@/mixins/GetAllCategories.js";
import { GetAllServiceTypes } from "@/mixins/GetAllServiceTypes.js";
import { GetAllManufacturer } from "@/mixins/GetAllManufacturer.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetCatagoryBasedOnMasterCatagory } from "@/mixins/GetCatagoryBasedOnMasterCatagory.js";
import {
  CreateServicesUnderCategories,
  UpdateMasterServices,
} from "@/graphql/mutations.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
// import { GenerateS3URL } from "@/mixins/GeneratePreAsignedURL.js";
var aws = require("aws-sdk");
export default {
  components: {
    Snackbar,
  },
  mixins: [
    GetAllCategories,
    GetAllManufacturer,
    GetAllServiceTypes,
    GetCatagoryBasedOnMasterCatagory,
    // GenerateS3URL,
  ],
  props: {
    StoreObj: Object,
    addCatalogDialog: Boolean,
  },
  data: () => ({
    count: 0,
    Toggle: 0,
    uuid: "",
    category_id: "",
    manufacturer: "",
    service_name: "",
    service_credits: "",
    Master_Category: "",
    service_ticket_prefix: "",
    isLoading: false,
    files: [],
    serviceType: [],
    service_s3_files: [],
    GetAllServiceTypeList: [],
    GetAllManufacturerList: [],
    GetAllCategoriesListBasedOnMC: [],
    SnackBarComponent: {},
  }),
  watch: {
    addCatalogDialog(val) {
      if (val == true) {
        if (this.StoreObj.action == "EDIT") {
          this.Master_Category = this.StoreObj.master_category_id;
          this.category_id = this.StoreObj.category_id;
          this.manufacturer = this.StoreObj.organization_id;
          this.serviceType = this.StoreObj.service_type_ids;
          this.service_name = this.StoreObj.service_name;
          this.service_ticket_prefix = this.StoreObj.service_ticket_prefix;
          this.service_credits = this.StoreObj.service_credits;
        }
      }
    },
    Master_Category(val) {
      this.GetAllCategoriesBasedOnMasterCatagoryMethod(val);
    },
    manufacturer(val) {
      this.GetAllServiceTypeMethod(val, "ACTIVE");
    },
  },
  mounted() {
    this.GetAllCategoriesMethod("ACTIVE", "MASTER_CATEGORY");
    this.GetAllBMSMenufacturerMethod("ACTIVE");
  },
  methods: {
    async uploadMediaToS3() {
      for (let i = 0; i< this.files.length ; i++ ) {
        let result = await this.GenerateS3URLMethods(
          `${new Date().getTime()}.jpg`,
          this.files[i]
        );
        let Obj = {
          file_name: this.files[i].name,
          file_type: this.files[i].type,
          file_url: result.profile_pic_url,
        };
        this.service_s3_files.push(Obj);
        if ( i == this.files.length -1 ) {
          if (this.StoreObj.action == 'CREATE') {
            this.createMasterService()
          } else {
            this.updateMasterService()
          }
        }
      }
      // this.uuid = uuidv4();
      // const fr = new FileReader();
      // fr.readAsDataURL(this.files[this.count]);
      // fr.addEventListener("load", async () => {
      //   var imageUrl = [];
      //   imageUrl = fr.result.replace(
      //     "data:" + this.files.type + ";base64,",
      //     ""
      //   );
      //   var keyValue = `${this.category_id}/${this.uuid}/${
      //     this.files[this.count].name
      //   }`;
      //   var params = {
      //     Bucket: "servicewrkcabinet",
      //     Key: keyValue,
      //     Body: Buffer.from(imageUrl, "base64"),
      //     ContentType: this.files[this.count].type,
      //     ACL: "public-read",
      //   };
      //   var s3Client = new aws.S3({
      //     region: "ap-south-1",
      //     accessKeyId: "AKIARAJXABES6RGOPDVD",
      //     secretAccessKey: "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",
      //   });
      //   console.warn("params", params);
      //   await s3Client.putObject(params).promise();
      //   this.urls3 =
      //     "https://servicewrkcabinet.s3.ap-south-1.amazonaws.com" +
      //     "/" +
      //     keyValue.trim();
      //   if (this.count != this.files.length - 1) {
      //     this.service_s3_files.push({
      //       file_name: this.files[this.count].name,
      //       file_type: this.files[this.count].type,
      //       file_url:
      //         "https://servicewrkcabinet.s3.ap-south-1.amazonaws.com" +
      //         "/" +
      //         keyValue.trim(),
      //     });
      //     this.count += 1;
      //     this.uploadMediaToS3();
      //   } else if (this.count == this.files.length - 1) {
      //     this.service_s3_files.push({
      //       file_name: this.files[this.count].name,
      //       file_type: this.files[this.count].type,
      //       file_url:
      //         "https://servicewrkcabinet.s3.ap-south-1.amazonaws.com" +
      //         "/" +
      //         keyValue.trim(),
      //     });
      //     this.createMasterService(this.service_s3_files);
      //   }
      // });
    },
    callmethodUppercase(service_ticket_prefix) {
      this.service_ticket_prefix = service_ticket_prefix.toUpperCase();
    },
    closeorgdialog() {
      this.$refs.form.reset();
      this.AddOrgdialog = false;
      this.category_id = "";
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        if (this.StoreObj.action == "CREATE") {
          if (this.files.length != 0) {
            this.service_s3_files = [];
            this.count = 0;
            this.uploadMediaToS3();
          } else {
            this.createMasterService();
          }
        } else if (this.StoreObj.action == "EDIT") {
          this.updateMasterService();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fill all the required details",
        };
      }
    },
    async updateMasterService() {
      this.isLoading = true;
      try {
        var inputParams = {
          updater_user_id: this.$store.getters.get_current_user_details.user_id,
          service_id: this.StoreObj.service_id,
          service_type_ids: this.serviceType,
          service_credits: this.service_credits,
          service_name: this.service_name,
          service_ticket_prefix: this.service_ticket_prefix.toUpperCase(),
        };
        let result = await API.graphql(
          graphqlOperation(UpdateMasterServices, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateMasterServices);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.addCatalogDialogEmit((this.Toggle = 2));
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    async createMasterService(service_s3_files) {
      this.isLoading = true;
      try {
        var inputParams = {
          creator_user_id: this.$store.getters.get_current_user_details.user_id,
          service_id: this.uuid,
          service_type_ids: this.serviceType,
          master_category_id: this.Master_Category,
          service_credits: this.service_credits,
          service_name: this.service_name,
          organization_id: this.manufacturer,
          category_id: this.category_id,
          service_ticket_prefix: this.service_ticket_prefix.toUpperCase(),
        };
        if (service_s3_files.length != 0) {
          inputParams.service_s3_files = this.service_s3_files;
        }
        let result = await API.graphql(
          graphqlOperation(CreateServicesUnderCategories, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(
          result.data.CreateServicesUnderCategories
        );
        if (ResultObject.status == "SUCCESS") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.status_message,
          };
          this.addCatalogDialogEmit((this.Toggle = 2));
        }
      } catch (error) {
        console.warn("Error", error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    addCatalogDialogEmit(Toggle) {
      this.isLoading = false;
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
