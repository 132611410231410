import { ListCategories } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllCategories = {
  data() {
    return {
      overlay: false,
      loading : false,
      noDataText: "",
      GetAllCategoriesList: [],
      GetAllMasterCategoriesList: [],
      GetAllCategoriesListFilter: [],
    };
  },
  methods: {
    async GetAllCategoriesMethod(category_status, category_type) {
      try {
        this.overlay = true;
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(ListCategories, {
            input: {
              category_status: category_status,
              category_type: category_type,
            },
          })
        );
        switch (category_type) {
          case "CATEGORY":
            this.GetAllCategoriesList = JSON.parse(
              result.data.ListCategories
            ).data.Items;
            this.GetAllCategoriesListFilter = JSON.parse(
              result.data.ListCategories
            ).data.Items;
            if (this.$route.name == "Catalog") {
              this.GetAllCategoriesList.unshift({
                category_name: "ALL",
                category_id: null,
              });
            }
            break;
          case "MASTER_CATEGORY":
            this.GetAllMasterCategoriesList = JSON.parse(
              result.data.ListCategories
            ).data.Items;
            if (this.$route.name == "Category") {
              this.GetAllMasterCategoriesList.unshift({
                category_name: "All",
                category_id: null,
              });
            }
            break;
        }
        this.overlay = false;
        this.loading = false;
      } catch (error) {
        this.overlay = false;
        this.loading = false;
        this.GetAllCategoriesList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
