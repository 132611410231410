<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddCatalog
      :addCatalogDialog="addCatalogDialog"
      :StoreObj="StoreObj"
      @clicked="addCatalogDialogEmit"
    />
    <DeleteCatalog
      :dialogDeleteCatalog="dialogDeleteCatalog"
      :StoreObj="StoreObj"
      @clicked="dialogDeleteCatalogEmit"
    />
    <DownloadAttachmentsDialog
      :StoreObj="StoreObj"
      :dialogDownloadAttachments="dialogDownloadAttachments"
      @clicked="dialogDownloadAttachmentsEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="5" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">Catalogues</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="7" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-select
              dense
              outlined
              label="Filter by Category"
              class="FontSize mt-9 field_label_size field_height mr-2 maxWidthSmall"
              v-model="category_id"
              :items="GetAllCategoriesList"
              item-text="category_name"
              item-value="category_id"
            ></v-select>
            <v-select
              outlined
              dense
              class="FontSize mt-9 maxWidthXSmall field_label_size field_height mr-2"
              label="Status"
              v-model="service_status"
              :items="StatusItems"
            ></v-select>
            <v-text-field
              dense
              outlined
              class="FontSize mt-9 field_label_size field_height mr-2"
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  @click="
                    (addCatalogDialog = true), (StoreObj.action = 'CREATE')
                  "
                  v-on="on"
                  v-bind="attrs"
                  class="ml-2 mt-3 primary white--text"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span class="white--text">Add Catalogue</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-data-table
          dense
          :search="search"
          :headers="headers"
          :items="GetAllCatalogList"
          :no-data-text="noDataText"
          :footer-props="{
            'items-per-page-options': [100, 200, 500],
          }"
          :items-per-page="100"
          fixed-header
          :height="TableHeight"
        >
          <template v-slot:[`item.service_created_on`]="{ item }">
            <span class="FontSize">{{
              new Date(item.service_created_on * 1000).toLocaleString("en-GB")
            }}</span>
          </template>
          <template v-slot:[`item.service_name`]="{ item }">
            <span class="FontSize">{{ item.service_name }}</span>
          </template>
          <template v-slot:[`item.service_ticket_prefix`]="{ item }">
            <span class="FontSize">{{ item.service_ticket_prefix }}</span>
          </template>
          <template v-slot:[`item.service_credits`]="{ item }">
            <span class="FontSize">{{ item.service_credits }}</span>
          </template>
          <template v-slot:[`item.Actions`]="{ item }">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="checkItem(item, 'EDIT')"
                  color="primary"
                  v-if="item.service_status == 'ACTIVE'"
                  small
                  v-on="on"
                  class="mr-2"
                >
                  mdi-pencil-plus
                </v-icon>
              </template>
              <span class="white--text">Update Catalogue</span>
            </v-tooltip>
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="checkItem(item, 'DELETE')"
                  :color="item.service_status == 'ACTIVE' ? 'red' : 'green'"
                  small
                  v-on="on"
                  class="red--text"
                >
                  {{
                    item.service_status == "ACTIVE" ? "mdi-close-thick" : "mdi-check"
                  }}
                </v-icon>
              </template>
              <span
                class="white--text"
                v-text="
                  item.service_status == 'ACTIVE'
                    ? 'De-Activate this Catalog ?'
                    : 'Activate this Catalog ?'
                "
              ></span>
            </v-tooltip>
            <v-tooltip
              bottom
              color="primary"
              v-if="
                item.service_s3_files != null &&
                item.service_s3_files != undefined
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  @click="checkItem(item, 'DOWNLOAD')"
                  color="primary"
                  small
                  v-on="on"
                  class="ml-2"
                >
                  mdi-paperclip
                </v-icon>
              </template>
              <span class="white--text" v-text="'Download Attachments'"></span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllCatalog } from "@/mixins/GetAllCatalog.js";
import { GetAllCategories } from "@/mixins/GetAllCategories.js";
import Overlay from "@/components/Extras/Overlay.vue";
import AddCatalog from "@/components/Catalog/Dialogs/AddCatalog.vue";
import DeleteCatalog from "@/components/Catalog/Dialogs/DeleteCatalog.vue";
import DownloadAttachmentsDialog from "@/components/Catalog/Dialogs/DownloadAttachmentsDialog.vue";
export default {
  components: {
    Overlay,
    AddCatalog,
    DeleteCatalog,
    DownloadAttachmentsDialog,
  },
  mixins: [GetAllCatalog, GetAllCategories],
  data: () => ({
    TableHeight : 0,
    search: "",
    noDataText : '',
    service_status: "ACTIVE",
    overlay: false,
    category_id: null,
    addCatalogDialog: false,
    dialogDeleteCatalog: false,
    dialogDownloadAttachments: false,
    GetAllCategoriesList: [],
    StatusItems: ["ACTIVE", "INACTIVE"],
    headers: [
      { text: "Service Name", value: "service_name" },
      { text: "Ticket Prefix", value: "service_ticket_prefix" },
      { text: "Service Credits", value: "service_credits" },
      { text: "Created On", value: "service_created_on" },
      { text: "Actions", value: "Actions" },
    ],
    StoreObj: {},
  }),
  watch: {
    category_id() {
      this.GetAllCatalogMethod(this.service_status, this.category_id);
    },
    service_status() {
      this.GetAllCatalogMethod(this.service_status, this.category_id);
    },
  },
  mounted() {
    this.TableHeight = window.innerHeight - 200;
    this.GetAllCategoriesMethod("ACTIVE", "CATEGORY");
    this.GetAllCatalogMethod(this.service_status, this.category_id);
  },
  methods: {
    dialogDownloadAttachmentsEmit() {
      this.dialogDownloadAttachments = false;
    },
    dialogDeleteCatalogEmit(Toggle) {
      this.dialogDeleteCatalog = false;
      if (Toggle == 2) {
        this.GetAllCatalogMethod(this.service_status, this.category_id);
      }
    },
    addCatalogDialogEmit(Toggle) {
      this.addCatalogDialog = false;
      if (Toggle == 2) {
        this.GetAllCatalogMethod(this.service_status, this.category_id);
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "EDIT") {
        this.addCatalogDialog = true;
      } else if (action == "DELETE") {
        this.dialogDeleteCatalog = true;
      } else if (action == "DOWNLOAD") {
        this.dialogDownloadAttachments = true;
      }
    },
  },
};
</script>
