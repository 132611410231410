import { ListAllMasterServices } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllCatalog = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllCatalogList: [],
      GetAllCatalogListFilter: [],
    };
  },
  methods: {
    async GetAllCatalogMethod(service_status, category) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListAllMasterServices, {
            input: {
              service_status: service_status,
              category_id: category,
            },
          })
        );
        this.GetAllCatalogList = result.data.ListAllMasterServices.data.items;
        this.GetAllCatalogFilter = result.data.ListAllMasterServices.data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllCatalogList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
