<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogDownloadAttachments" width="500px">
      <v-card>
        <v-toolbar dense class="elevation-0 primary">
          <v-toolbar-title class="white--text"
            >Download Attachments</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="dialogDownloadAttachmentsEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text align="center">
          <v-data-table
            dense
            class="mt-4"
            :headers="headers"
            :items="StoreObj.service_s3_files"
            :no-data-text="noDataText"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50],
            }"
            hide-default-footer
          >
            <template v-slot:[`item.file_name`]="{ item }">
              <span class="FontSize">{{ item.file_name }}</span>
            </template>
            <template v-slot:[`item.Action`]="{ item }">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="downloadItem(item)"
                    color="primary"
                    small
                    v-on="on"
                  >
                    mdi-download
                  </v-icon>
                </template>
                <span
                  class="white--text"
                  v-text="`Download ${item.file_name}`"
                ></span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
    dialogDownloadAttachments: Boolean,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    noDataText : "",
    isLoading: false,
    SnackBarComponent: {},
    headers: [
      { text: "File Name", value: "file_name" },
      { text: "Action", value: "Action" },
    ],
  }),
  methods: {
    downloadItem(item) {
      window.open(item.file_url);
    },
    dialogDownloadAttachmentsEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
<style>
.primary {
  color: #d97d54 !important;
}
</style>
