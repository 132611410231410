import { List_Categories_By_Master_Category } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetCatagoryBasedOnMasterCatagory = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetCatagoryBasedOnMasterCatagory: [],
    };
  },
  methods: {
    async GetAllCategoriesBasedOnMasterCatagoryMethod(
      category_id,
      category_status
    ) {
      try {
        this.overlay = true;
        this.GetAllCategoriesListBasedOnMC = [];
        let result = await API.graphql(
          graphqlOperation(List_Categories_By_Master_Category, {
            input: {
              master_category_id: category_id,
              category_status:
                category_status == undefined ? "ACTIVE" : category_status,
            },
          })
        );
        this.GetAllCategoriesListBasedOnMC = JSON.parse(
          result.data.List_Categories_By_Master_Category
        ).data.items;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllCategoriesListBasedOnMC = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
